import React from 'react';

import { ParticipantStep } from '@wix/ambassador-challenges-v1-participant/types';

import { ButtonNames } from '../../../../../contexts/main/biInterfaces';

import { Spinner } from '../../../../../components-shared/Spinner';

import { StepsListAsTiles } from '../../components/StepsListAsTiles';

import {
  InjectedBiLoggerProps,
  withBi,
  withExperiments,
} from '@wix/yoshi-flow-editor';

import { SectionsListAsTiles } from '../../components/SectionsListAsTiles';
import { ChallengeOverview } from '../../components/ChallengeOverview';
import { StepViewBreadcrumbs } from '../../components/StepViewBreadcrumbs';
import { ToastType } from '../../../../../contexts/ToastContext/interfaces';

import { classes, st } from './SidebarLayoutForParticipant.st.css';
import '../../../../../components-shared/Ricos/rce/rce.global.scss';
import utils, {
  ChallengeEntityType,
  getFirstAvailableStep,
  getFlatStepsList,
  getNextStepToComplete,
  getProgramProgress,
  IFollowingEntity,
  isStepResolved,
} from '../utils';
import {
  getWixAdvertisementBannerHeight,
  setElementToViewport,
} from '../../../../../services/scrollHelpers';
import { SideBarStepView } from '../../components/SideBarStepView';
import { OverviewSidebar } from '../../components/OverviewSidebar';
import { ChallengeSummary } from '../../components/ChallengeSummary/ChallengeSummary';
import {
  FinishedNotification,
  SectionControls,
  StepControls,
} from '../../components/StepControls';
import { SidebarSectionView } from '../../components/SidebarSectionView';
import {
  getFirstAvailableStepFromSection,
  getFirstSection,
  sectionCannotBeOpenedInView,
} from '../../../../../selectors/sections';
import { SidebarControlsContainer } from '../../components/SidebarControlsContainer/SidebarControlsContainer';
import { SidebarLayoutButton } from '../../components/Buttons/SidebarLayoutButton/SidebarLayoutButton';
import { memberWebAppButtonClick as memberWebAppButtonClickV2 } from '@wix/bi-logger-challenges-member-web/v2';
import { withGeneralData } from '../../../../../contexts/GeneralDataProvider/withGeneralData';
import { withToast } from '../../../../../contexts/ToastContext/withToast';
import { ISidebarMobileScreenFromSettings } from '../../../Settings.mobile/tabs/Design/DesignSettings';
import { ProgramRejoin } from '../../components/ProgramRejoin';
import { FormQuiz, IFormHandleExtended } from '../../components/FormQuiz';
import { FormQuizControls } from '../../components/FormQuizControls';
import { SidebarLayoutBaseContext } from './contexts/SidebarLayoutBase/SidebarLayoutBaseContext';
import { sidebarLayoutDataHooks } from './sidebarLayoutDataHooks';
import {
  ILeaveChallengeModalContext,
  withLeaveChallengeModal,
} from '../../../../../contexts/LeaveChallengeModal/LeaveChallengeModalContext';
import { LeaveModalType } from '../../../../../contexts/LeaveChallengeModal/LeaveChallengeModalProvider';
import { isSelfPaced } from '../../../../../selectors/isSelfPaced';
import {
  ChallengeNotification,
  IChallengeNotificationType,
} from '../../../../ChallengesPage/Widget/components/ChallengeNotification';
import {
  IChallengePageProps,
  IChallengePageState,
} from '../../../../ChallengesPage/Widget/interfaces';
import { ProgramUnavailable } from '../../../../ChallengesPage/Widget/components/ProgramUnavailable';
import { LayoutSelectedTab } from '../../../../ChallengesPage/Settings/tabs/Design/components/OverviewScheduleSwitcher/constants';
import { IGeneralDataContext } from '../../../../../contexts/GeneralDataProvider/GeneralDataContext';
import { isUserCompleted } from '../../../../../contexts/User/helpers/userTypeHandlers';
import { getQuizId, getQuizSubmissionId } from '../../../../../selectors/step';
import { SocialGroupLink } from '../../../../../components-shared/SocialGroup/SocialGroupLink';
import { isVideoStep } from '../../../../../selectors/video';
import { isProgramProgressReachedEnd } from '../../../../../selectors/participant/isProgramProgressReachedEnd';

export interface IExtendWidget {
  isEditor: boolean;
  isMobile: boolean;
  lng: string;
  isFullscreen: boolean;
  experiments: any;
  isRTL: boolean;
}

type Props = IChallengePageProps &
  IExtendWidget &
  InjectedBiLoggerProps &
  ILeaveChallengeModalContext &
  IGeneralDataContext;

class SidebarComponent extends React.Component<Props, IChallengePageState> {
  static displayName = 'ChallengePage';

  private readonly wrapperRef = null;
  private readonly stepViewRef = null;
  private readonly formQuizRef: React.RefObject<IFormHandleExtended> = null;
  private readonly breadRef: React.RefObject<HTMLButtonElement> =
    React.createRef();
  private readonly sectionRef: React.RefObject<HTMLElement> = React.createRef();

  constructor(props: Props) {
    super(props);

    let { chosenSectionTileId, currentStep } = this.getDeepLinkInitialState();

    if (
      !currentStep &&
      !chosenSectionTileId &&
      !this.props.settings.overviewTabDisplay
    ) {
      const flatStepsList = getFlatStepsList(props);
      if (getProgramProgress(flatStepsList).isProgramNotStarted) {
        const firstSection = getFirstSection(props.listParticipantSections);

        if (!sectionCannotBeOpenedInView(firstSection)) {
          chosenSectionTileId = firstSection.id;
        } else {
          currentStep = getFirstAvailableStep(flatStepsList);
        }
      } else {
        currentStep = getFirstAvailableStep(flatStepsList);
      }
    }

    this.state = {
      wixAdvertisementBannerHeight: undefined,
      currentStep,
      chosenSectionTileId,
      selectedPaymentOption: 'SinglePayment',
      sharedCurrentDateForWeeksSelector: null,
      mobileScreen: 'list',
      isCurrentStateWasResolved: false,
      videoStepAccepted: false,
      isVideoLoadingError: false,
      isAutoNavigateToNextStep: false,
      isAutoPlayVideo: false,
      isSectionsWithoutDescriptionEnabled: false,
    };

    this.wrapperRef = React.createRef();
    this.stepViewRef = React.createRef();
    this.formQuizRef = React.createRef<IFormHandleExtended>();
  }

  private getDeepLinkInitialState() {
    let chosenSectionTileId = null;
    let currentStep = null;
    if (this.props.selectedSection) {
      chosenSectionTileId = this.props.selectedSection.id;
    }
    if (this.props.selectedStep) {
      currentStep = this.props.selectedStep;
    }
    return { chosenSectionTileId, currentStep };
  }

  static getDerivedStateFromProps = (
    props: IChallengePageProps & { isEditor: boolean },
  ): Partial<IChallengePageState> => {
    const result: any = {};

    if (props.isEditor) {
      result.currentStep = props?.participantSteps?.steps?.[0];

      if (
        props.sidebarLayoutMobileScreen ===
        ISidebarMobileScreenFromSettings.List
      ) {
        result.mobileScreen = 'list';
      } else if (
        props.sidebarLayoutMobileScreen ===
          ISidebarMobileScreenFromSettings.Overview ||
        props.sidebarLayoutMobileScreen ===
          ISidebarMobileScreenFromSettings.Schedule
      ) {
        result.mobileScreen = 'view';
      }
    }

    // we should reset the selected section, if some step will be selected
    if (result.currentStep) {
      result.chosenSectionTileId = null;
    }

    return result;
  };

  async componentDidMount() {
    this.setState({
      wixAdvertisementBannerHeight: getWixAdvertisementBannerHeight(),
    });

    if (this.props.selectedSection) {
      this.onSectionChosen(this.props.selectedSection.id);
    }
    if (this.props.selectedStep) {
      this.onStepChosen(this.props.selectedStep);
    }
    if (this.props.isDescriptionShown) {
      this.onStepChosen(null);
    }
  }

  componentDidUpdate(prevProps): void {
    const {
      t,
      showToast,
      listParticipantSections,
      participantSteps: { steps = [] } = {},
      updateParticipantStepStatus,
    } = this.props;

    const isSections = this.props.listParticipantSections?.length;

    if (
      prevProps.isResolveStepRequestInProgress &&
      !this.props.isResolveStepRequestInProgress
    ) {
      const step = this.props.resolveStepData;
      const error = this.props.resolveStepError;

      if (!error) {
        updateParticipantStepStatus({
          replace: true,
          stepId: step?.id,
          transitions: step?.transitions,
          quizSubmission: step?.quizSubmission,
          steps,
          sections: isSections ? listParticipantSections : [],
        });

        this.setState(
          {
            currentStep: {
              ...this.state.currentStep,
              ...step,
            },
          },
          () => {
            void this.checkAutoNavigate();
          },
        );
      } else {
        showToast(t('toast.step-completed-error'), {
          type: ToastType.error,
        });
      }
    }
  }

  sendBiButtonClick(buttonName: ButtonNames, step?: string) {
    void this.props.bi.report(
      memberWebAppButtonClickV2({
        buttonName,
        step,
      }),
    );
  }

  setStep(currentStep: ParticipantStep, preventScrolling?: boolean) {
    this.setState(
      {
        chosenSectionTileId: null,
        currentStep,
        mobileScreen: this.props.isMobile ? 'view' : undefined,
      },
      () => {
        if (!preventScrolling) {
          document
            .querySelector(`li[data-id="${currentStep?.id}"]`)
            ?.scrollIntoView({
              behavior: 'smooth',
            });
        }
      },
    );
  }

  onSectionChosen = (chosenSectionTileId: string) => {
    if (this.props.isResolveStepRequestInProgress) {
      return;
    }

    this.scrollToContent();
    const goToSection = () => {
      this.setState(
        {
          currentStep: null,
          mobileScreen: 'view',
          chosenSectionTileId,
        },
        () => {
          if (this.sectionRef.current) {
            this.sectionRef.current.focus();
          }
        },
      );
    };
    if (this.formQuizRef?.current?.dirty) {
      this.props.showModal(
        {
          participantId: this.props.participant.id,
          modalType: LeaveModalType.LeaveSession,
        },
        undefined,
        goToSection,
      );
      return;
    }
    goToSection();
  };

  onStepChosen = (step: ParticipantStep): void => {
    if (this.props.isResolveStepRequestInProgress) {
      return;
    }

    this.scrollToContent();
    this.sendBiButtonClick(
      step ? ButtonNames.StepTileSelection : ButtonNames.OverviewTileSelection,
      step?.id,
    );

    const goToNextStep = () => {
      this.setState(
        {
          chosenSectionTileId: null,
          currentStep: step,
          mobileScreen: 'view',
        },
        () => {
          if (this.breadRef.current) {
            this.breadRef.current.focus();
          }
        },
      );
    };
    if (this.formQuizRef?.current?.dirty) {
      this.props.showModal(
        {
          participantId: this.props.participant.id,
          modalType: LeaveModalType.LeaveSession,
        },
        undefined,
        goToNextStep,
      );
      return;
    }
    goToNextStep();
  };

  async onNextEntity(
    nextEntity: IFollowingEntity,
    withBI = true,
  ): Promise<void> {
    switch (nextEntity.type) {
      case ChallengeEntityType.STEP:
        this.onStepChosen(nextEntity.step);
        if (withBI) {
          this.sendBiButtonClick(ButtonNames.NextStep);
        }
        break;
      case ChallengeEntityType.SECTION:
        this.onSectionChosen(nextEntity.section?.id);
        if (withBI) {
          this.sendBiButtonClick(ButtonNames.NextSection);
        }
        break;
      default:
        return null;
    }
  }

  private scrollToContent() {
    const { isMobile } = this.props;

    setElementToViewport(this.stepViewRef.current);

    if (isMobile) {
      window?.scrollTo?.(0, 0);
    }
  }

  onStepResolve = async (step: ParticipantStep) => {
    this.setState(
      {
        currentStep: step,
      },
      async () => {
        this.sendBiButtonClick(ButtonNames.StepComplete);

        void this.resolveStep();
      },
    );
  };

  async resolveStep() {
    const {
      listParticipantSections,
      challengeData: { challenge },
      participantSteps: { steps = [] } = {},
      updateParticipantStepStatus,
      resolveStep,
    } = this.props;

    const isSections =
      isSelfPaced(challenge) && this.props.listParticipantSections?.length;

    return new Promise<void>((resolve, reject) => {
      this.setState({}, async () => {
        // todo: fixme
        const stepForView = this.state.currentStep;

        if (stepForView?.id) {
          try {
            const {
              isStepResolved: isCurrentStepResolved,
              currentStepAfterUpdate,
            } = utils.getResolveStepData(stepForView, null);

            this.setState(
              {
                currentStep: currentStepAfterUpdate,
                isCurrentStateWasResolved: isStepResolved(stepForView),
              },
              async () => {
                /*
                    Optimistic Update.
                    Change the status of current step and then run resolve request.
                    Otherwise, the current step can be changed faster, then request finished.
                   */
                updateParticipantStepStatus({
                  stepId: currentStepAfterUpdate?.id,
                  transitions: currentStepAfterUpdate?.transitions,
                  steps,
                  sections: isSections ? listParticipantSections : [],
                });
                await resolveStep({
                  stepId: stepForView.id,
                  isUpdate: isCurrentStepResolved,
                });

                resolve();
              },
            );
          } catch (err) {
            console.error(err);
            reject(err);
          }
        } else {
          const err = "Can't find step for resolve.";
          console.error(err);
          reject(err);
        }
      });
    });
  }

  async resolveQuiz(quizSubmissionId?: string) {
    const { resolveStep } = this.props;
    const stepForView = this.state.currentStep;

    if (stepForView?.id) {
      await resolveStep({
        stepId: stepForView.id,
        unresolvedMessage: {
          type: ToastType.error,
          key: 'toast.quiz-completed-error',
        },
        quizSubmission: {
          quizSubmissionId,
          score: 100, // for the future
        },
      });
    } else {
      console.error("Can't find step for resolve.");
    }
  }

  async checkAutoNavigate() {
    if (this.state.isAutoNavigateToNextStep) {
      const currentStep = this.state.currentStep;
      const nextEntity = utils.getFollowingEntity(
        currentStep?.id,
        null,
        {
          listParticipantSections: this.props.listParticipantSections,
          participantSteps: this.props.participantSteps,
        },
        false,
      );
      const isNextVideo = isVideoStep((nextEntity as any)?.step?.source);

      this.setState(({ isAutoPlayVideo }) => ({
        isAutoNavigateToNextStep: false,
        isAutoPlayVideo: isNextVideo ? isAutoPlayVideo : false,
      }));

      await this.onNextEntity(nextEntity, false);
    }
  }

  getIsStartDisabled() {
    const isSections = this.props.listParticipantSections?.length;
    if (isSections) {
      const nextStep = getFirstAvailableStepFromSection(
        this.props.listParticipantSections || [],
      );
      const nextSection = getFirstSection(this.props.listParticipantSections);
      if (sectionCannotBeOpenedInView(nextSection)) {
        return !nextStep;
      }
      return !nextStep && !nextSection;
    }

    return false;
  }

  goToMobileList = () => {
    const { isMobile } = this.props;
    if (isMobile) {
      this.setState(
        {
          mobileScreen: 'list',
        },
        () => {
          // window?.scrollTo?.(0, 0);
        },
      );
    }
  };

  getQuizData = () => {
    const step = this.state.currentStep;
    let isQuiz: boolean = false;
    let formId: string;
    let quizSubmissionId: string;
    if (step) {
      formId = getQuizId(step?.source);
      isQuiz = !!formId;
      quizSubmissionId = getQuizSubmissionId(step);
    }
    const isResolved = isStepResolved(this.state.currentStep);

    return {
      isQuiz,
      formId,
      quizSubmissionId,
      isResolved,
    };
  };

  render() {
    const { challengeData, isLoading, isFullWidthLayout, settings, isMobile } =
      this.props;
    const cssPBEnabled = this.isCssPBEnabled();

    const { mobileScreen } = this.state;
    // eslint-disable-next-line no-nested-ternary
    return challengeData && challengeData.challenge ? (
      isLoading ? (
        <div
          className={classes.fullPageSpinner}
          data-hook={sidebarLayoutDataHooks.spinner()}
        >
          <Spinner />
        </div>
      ) : (
        <section
          data-hook={sidebarLayoutDataHooks.main()}
          className={st(classes.root, {
            mobile: isMobile,
            mobileScreen,
            newScrolling: true,
            fullWidthLayout: isFullWidthLayout,
            withoutSpace:
              !cssPBEnabled && parseInt(settings.sidebarLayoutSpace, 10) === 0,
            align: settings.sidebarLayoutTextAlignment,
            fullscreen: this.props.isFullscreen,
          })}
        >
          {this.renderWithChallenge()}
        </section>
      )
    ) : null;
  }

  private isCssPBEnabled() {
    const { experiments, isCssPerBreakpoint } = this.props;
    return (
      isCssPerBreakpoint && experiments.enabled('specs.programs.OOIStyleBP')
    );
  }

  private openFirstSectionIfAvailable() {
    const firstSection = getFirstSection(this.props.listParticipantSections);
    const firstSectionId = firstSection?.id;
    if (!sectionCannotBeOpenedInView(firstSection) && firstSectionId) {
      this.onSectionChosen(firstSectionId);
      return true;
    }
  }

  renderWithChallenge() {
    const stickyPositionTop = this.state.wixAdvertisementBannerHeight
      ? `${this.state.wixAdvertisementBannerHeight}px`
      : undefined;
    const cssPBEnabled = this.isCssPBEnabled();

    return (
      <>
        <ChallengeNotification // no notifications now, saved for future cases
          challengeJoinRestrictions={[]}
          type={IChallengeNotificationType.Sidebar}
        />
        <SidebarLayoutBaseContext.Provider
          value={{
            formQuizRef: this.formQuizRef,
            currentStep: this.state.currentStep,
            isCurrentStepHidden:
              !this.state.chosenSectionTileId &&
              this.state.currentStep?.id &&
              utils.isStepHidden(
                this.props.challengeData?.challenge,
                getFlatStepsList({
                  listParticipantSections: this.props.listParticipantSections,
                  participantSteps: this.props.participantSteps,
                }),
                this.state.currentStep,
              ),
            goToCurrentStep: () => {
              this.setStep(
                utils.getFirstAvailableStep(getFlatStepsList(this.props)),
              );
            },
            resolveCurrentStep: async () => {
              return this.resolveStep();
            },
            isAutoPlayVideo: this.state.isAutoPlayVideo,
            changeAutoplayOptions: async (isAutoplayEnabled: boolean) => {
              return new Promise((resolve) => {
                this.setState(
                  {
                    isAutoNavigateToNextStep: isAutoplayEnabled,
                    isAutoPlayVideo: isAutoplayEnabled,
                  },
                  resolve,
                );
              });
            },
            checkAutoNavigate: async () => {
              return this.checkAutoNavigate();
            },
            pageRef: this.props.pageRef,
            videoStepAccepted: this.state.videoStepAccepted,
            setVideoStepAccepted: (videoStepAccepted: boolean) => {
              this.setState({
                videoStepAccepted,
              });
            },
            isVideoLoadingError: this.state.isVideoLoadingError,
            setIsVideoLoadingError: (isVideoLoadingError: boolean) => {
              this.setState({
                isVideoLoadingError,
              });
            },
            isSectionsWithoutDescriptionEnabled:
              this.state.isSectionsWithoutDescriptionEnabled,
            changeSectionsWithoutDescriptionEnabled: (
              isSectionsWithoutDescriptionEnabled,
            ) => {
              this.setState({
                isSectionsWithoutDescriptionEnabled,
              });
            },
          }}
        >
          <div className={classes.wrapper} ref={this.wrapperRef}>
            <div
              style={{
                top: stickyPositionTop,
              }}
              className={`${classes.column} ${classes.sidebar}`}
            >
              {this.renderSidebarContent()}
            </div>
            <div
              style={{
                width: cssPBEnabled
                  ? 'calc(var(--opgSidebarLayoutSpace) * 1px)'
                  : `${this.props.settings.sidebarLayoutSpace}px`,
              }}
            />
            <div className={`${classes.column} ${classes.contentBody}`}>
              {this.renderBodyContent()}
            </div>
          </div>
        </SidebarLayoutBaseContext.Provider>
      </>
    );
  }

  renderSidebarContent() {
    const { challengeData, listParticipantSections } = this.props;
    const { currentStep, chosenSectionTileId } = this.state;
    const { socialGroupId } = challengeData?.challenge?.settings;

    const isSections = listParticipantSections?.length;

    return (
      <>
        <div className={classes.summary}>
          <ChallengeSummary />
        </div>
        {this.renderSidebarStartButton()}
        <div className={classes.stepsListBox}>
          <OverviewSidebar
            onStepChosen={this.onStepChosen}
            isActive={!chosenSectionTileId && !currentStep}
          />
          {isSections ? this.renderSectionsList() : this.renderStepsList()}
        </div>
        {socialGroupId ? (
          <div className={classes.groupInSidebar}>
            <SocialGroupLink slug={socialGroupId} />
          </div>
        ) : null}
      </>
    );
  }

  renderSidebarStartButton() {
    if (!this.props.isMobile || this.isProgramFinished) {
      return null;
    }
    return (
      <div className={classes.startButton}>{this.renderStartButton()}</div>
    );
  }

  renderBodyContent() {
    const {
      challengeData: { challenge },
      isParticipantInSuspendedState,
      isParticipantInLockedState,
    } = this.props;
    const { chosenSectionTileId, currentStep } = this.state;
    const isSectionActive = !!chosenSectionTileId;
    const isStepActive = !!currentStep?.id;
    const isParticipantInNegativeState =
      isParticipantInSuspendedState || isParticipantInLockedState;
    const isStepActiveMagic =
      (isStepActive && this.props.shownTab !== LayoutSelectedTab.Overview) ||
      !this.props.settings.overviewTabDisplay;
    const isVideo = isVideoStep(currentStep?.source);

    return (
      <>
        <div
          className={`${classes.stepView} ${
            isParticipantInNegativeState ? classes.stepViewCentered : ''
          } ${isVideo ? classes.stepViewVideo : ''}`}
        >
          <div
            className={classes.stepViewContent}
            ref={this.stepViewRef}
            aria-live="polite"
            role="region"
          >
            {isSectionActive ? (
              <>
                {this.renderContentPlaceholders()}
                {this.renderSectionView()}
              </>
            ) : null}

            {!isSectionActive && isStepActiveMagic ? (
              <>
                {this.renderContentPlaceholders()}
                {this.renderStepView()}
              </>
            ) : null}

            {!isSectionActive && !isStepActiveMagic ? (
              <>
                <ChallengeOverview
                  challenge={challenge}
                  prefix={this.renderBreadcrumbs()}
                  pageRef={this.props.pageRef}
                />
                {this.renderContentPlaceholders('SidebarOverview', false)}
              </>
            ) : null}
          </div>
        </div>

        {this.renderOverviewControls()}
        {this.renderBaseControls()}
      </>
    );
  }

  renderContentPlaceholders(
    type: 'SidebarGeneral' | 'SidebarOverview' = 'SidebarGeneral',
    withMobileBreadcrumbs = true,
  ) {
    const { isParticipantInSuspendedState, isParticipantInLockedState } =
      this.props;

    return (
      <>
        {isParticipantInSuspendedState ? (
          <ProgramRejoin
            type={type}
            prefix={
              this.props.isMobile && withMobileBreadcrumbs
                ? this.renderBreadcrumbs()
                : null
            }
          />
        ) : null}
        {isParticipantInLockedState ? (
          <ProgramUnavailable
            type={type}
            prefix={
              this.props.isMobile && withMobileBreadcrumbs
                ? this.renderBreadcrumbs()
                : null
            }
          />
        ) : null}
      </>
    );
  }

  renderSectionView() {
    const { isParticipantInSuspendedState, isParticipantInLockedState } =
      this.props;
    const isParticipantInNegativeState =
      isParticipantInSuspendedState || isParticipantInLockedState;

    if (isParticipantInNegativeState) {
      return null;
    }

    return (
      <SidebarSectionView
        pageRef={this.props.pageRef}
        titleRef={this.sectionRef}
        goToCurrentStep={() => {
          const step = getFirstAvailableStepFromSection(
            this.props.listParticipantSections,
          );

          if (step) {
            this.setState({
              currentStep: step,
              chosenSectionTileId: null,
            });
          }
        }}
        currentSectionId={this.state.chosenSectionTileId}
        goToMobileList={this.goToMobileList}
      />
    );
  }

  renderSectionsList() {
    const { chosenSectionTileId, currentStep } = this.state;

    return (
      <>
        <SectionsListAsTiles
          dataHook={sidebarLayoutDataHooks.sectionsListTiles()}
          dataHookForStepsList={sidebarLayoutDataHooks.stepsListTiles()}
          chosenSectionTileId={chosenSectionTileId}
          currentStepId={currentStep?.id}
          onStepChosen={this.onStepChosen}
          onSectionChosen={this.onSectionChosen}
        />
      </>
    );
  }

  renderStepsList() {
    const {
      challengeData: { challenge },
      participantSteps: { steps } = { steps: [] },
      isParticipantStepsLoading,
    } = this.props;
    const { currentStep } = this.state;
    if (isParticipantStepsLoading) {
      return null;
    }

    return (
      <StepsListAsTiles
        steps={steps}
        isSPC={isSelfPaced(challenge)}
        currentStepId={currentStep?.id}
        onStepChosen={this.onStepChosen}
      />
    );
  }

  renderBreadcrumbs = () => {
    const { listParticipantSections } = this.props;
    const { currentStep } = this.state;

    return (
      <StepViewBreadcrumbs
        sections={listParticipantSections}
        currentStep={currentStep}
        goToMobileList={this.goToMobileList}
        onSectionChosen={this.onSectionChosen}
        btnRef={this.breadRef}
      />
    );
  };

  renderStepView() {
    const {
      challengeData: { challenge },
      isListParticipantSectionsRequestInProgress,
      listParticipantSections,
      isParticipantStepsLoading,
      participantSteps,
      isParticipantInSuspendedState,
      isParticipantInLockedState,
    } = this.props;
    const quizData = this.getQuizData();
    const isParticipantInNegativeState =
      isParticipantInSuspendedState || isParticipantInLockedState;

    if (isParticipantInNegativeState) {
      return null;
    }

    return (
      <div data-hook={sidebarLayoutDataHooks.stepViewWrapper()}>
        {quizData.isQuiz ? (
          <FormQuiz
            key={`form-quiz-${quizData.formId}`}
            formId={quizData.formId}
            submissionId={quizData.quizSubmissionId}
            onResolve={(lastSubmissionId: string) => {
              void this.resolveQuiz(lastSubmissionId);
            }}
            stepName={
              this.state.currentStep?.source?.settings?.general?.description
                ?.title
            }
            Breadcrumbs={this.renderBreadcrumbs}
            ref={this.formQuizRef}
          />
        ) : (
          <SideBarStepView
            dataHook={sidebarLayoutDataHooks.stepView()}
            participantSteps={participantSteps}
            listParticipantSections={listParticipantSections}
            challenge={challenge}
            isListParticipantSectionsRequestInProgress={
              isListParticipantSectionsRequestInProgress
            }
            goToCurrentStep={() => {
              this.setStep(
                utils.getFirstAvailableStep(getFlatStepsList(this.props)),
              );
            }}
            isParticipantStepsLoading={isParticipantStepsLoading}
            currentStep={this.state.currentStep}
            Breadcrumbs={this.renderBreadcrumbs}
            pageRef={this.props.pageRef}
          />
        )}
      </div>
    );
  }

  renderStartButton() {
    const { t } = this.props;
    const flatStepsList = getFlatStepsList(this.props);
    const { isProgramNotStarted } = getProgramProgress(flatStepsList);

    return (
      <SidebarLayoutButton
        disabled={this.getIsStartDisabled()}
        onClick={() => {
          if (isProgramNotStarted) {
            const isSectionOpened = this.openFirstSectionIfAvailable();
            if (isSectionOpened) {
              return;
            }
          }
          const nextStepToComplete = getNextStepToComplete(flatStepsList);
          if (nextStepToComplete) {
            this.setStep(nextStepToComplete, true);
            this.scrollToContent();
          } else {
            this.openFirstSectionIfAvailable();
          }

          this.sendBiButtonClick(ButtonNames.OverviewStartButton);
        }}
      >
        {isProgramNotStarted
          ? t('challenge.page.overview.start-btn')
          : t('challenge.page.overview.current-step')}
      </SidebarLayoutButton>
    );
  }

  get isProgramFinished() {
    const flatStepsList = getFlatStepsList(this.props);
    return getProgramProgress(flatStepsList).isProgramFinished;
  }

  renderOverviewControls() {
    const { isParticipantInSuspendedState, isParticipantInLockedState } =
      this.props;
    const { chosenSectionTileId, currentStep } = this.state;
    const isSectionActive = !!chosenSectionTileId;
    const isStepActive = !!currentStep?.id;
    const isOverviewActive = !isSectionActive && !isStepActive;
    const isParticipantInNegativeState =
      isParticipantInSuspendedState || isParticipantInLockedState;
    const { isQuiz } = this.getQuizData();

    const shouldShowOverviewControls =
      isOverviewActive &&
      !this.isProgramFinished &&
      !isParticipantInNegativeState &&
      !isQuiz;

    if (!shouldShowOverviewControls) {
      return null;
    }

    return (
      <SidebarControlsContainer>
        {this.renderStartButton()}
      </SidebarControlsContainer>
    );
  }

  renderBaseControls() {
    const {
      challengeData,
      participant,
      isParticipantInSuspendedState,
      isParticipantInLockedState,
      experiments,
    } = this.props;
    const { chosenSectionTileId, currentStep } = this.state;
    const isSectionActive = !!chosenSectionTileId && !currentStep?.id;
    const isStepActive = !!currentStep?.id;
    const isParticipantInNegativeState =
      isParticipantInSuspendedState || isParticipantInLockedState;
    const { formId, isQuiz } = this.getQuizData();
    const isCompletedChallenge =
      isUserCompleted(participant?.transitions?.[0]?.state) ||
      isProgramProgressReachedEnd(
        challengeData?.challenge,
        participant,
        experiments.enabled(
          'specs.programs.OOIEnableOptimisticUpdateForScheduled',
        ),
      );

    const shouldShowBaseControls =
      (isSectionActive || isStepActive || isQuiz) &&
      !isParticipantInNegativeState;

    if (isCompletedChallenge) {
      return <FinishedNotification />;
    } else if (!shouldShowBaseControls) {
      return null;
    }

    return (
      <>
        {isSectionActive ? (
          <SectionControls
            currentSectionId={this.state.chosenSectionTileId}
            steps={this.props.participantSteps}
            sections={this.props.listParticipantSections}
            onNextEntity={(entity) => this.onNextEntity(entity)}
            onStepChosen={(step) => this.onStepChosen(step)}
          />
        ) : null}
        {isStepActive && !isQuiz ? (
          <StepControls
            completeBtnDataHook={sidebarLayoutDataHooks.stepCompleteBtn()}
            nextBtnDataHook={sidebarLayoutDataHooks.stepNextBtn()}
            currentStep={this.state.currentStep}
            steps={this.props.participantSteps}
            sections={this.props.listParticipantSections}
            buttonState={this.props.buttonState}
            onStepResolve={(step) => this.onStepResolve(step)}
            onNextEntity={(entity) => this.onNextEntity(entity)}
          />
        ) : null}
        {isQuiz ? (
          <FormQuizControls
            formId={formId}
            onNextEntity={(entity) => this.onNextEntity(entity)}
          />
        ) : null}
      </>
    );
  }
}

export default withExperiments<any>(
  withBi(withGeneralData(withToast(withLeaveChallengeModal(SidebarComponent)))),
);
